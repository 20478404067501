import { FC, PropsWithChildren } from 'react'

export const RouteSearchBarContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="relative flex w-full max-w-full flex-col items-center justify-center">
      <div className="absolute inset-0 size-full"></div>
      <div className="bg-custom-bg bg-20-center absolute inset-0 size-full bg-cover bg-fixed bg-no-repeat"></div>
      <div className="relative z-10 mx-auto flex w-full max-w-screen-xl flex-col items-center justify-center px-12 text-[36px]">
        {children}
      </div>
    </div>
  )
}
