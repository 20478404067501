import { AppLayout, Vessel } from '@/root/modules/bluewave'
import { useGetPageContent } from '@/root/shared-hooks/sales-static/usePages'
import { Fleet } from '@/root/shared-types'
import { useCustomRouter } from '@/root/shared-hooks'

export const VesselPage = () => {
  const {
    query: { vessel },
  } = useCustomRouter()

  const { pageContent } = useGetPageContent('fleet', 'bluewave')

  const vesselProps = (pageContent.page as Fleet)?.vessels.find((v) => v.slug === vessel)

  const body =
    vesselProps?.body.replace(/<td\s*>|<\/td><td\s*>|<tr\s*>/g, (match: string) => {
      if (/<tr\s*>/.test(match)) {
        return `<tr class="border-b border-b-neutral-medium-lighter last:border-none last:mb-10 h-12">`
      }
      if (/<\/td><td\s*>/.test(match)) {
        return `</td><td class=" flex border-l border-l-neutral-medium-lighter h-12 justify-end text-end items-center">`
      }
      return match
    }) || ''

  return (
    <AppLayout>
      {vesselProps && <Vessel vesselName={vesselProps.name} images={vesselProps.images} body={body} />}
    </AppLayout>
  )
}
