import { FC } from 'react'
import { Reservation } from '@/root/shared-types/reservation/reservation-types'
import { useConfig, useCustomRouter, useGetTranslations, usePrintedTicketData } from '@/root/shared-hooks'
import Loader from '@/root/ui/bluewave/Loader/Loader'
import Banner from '@/root/ui/bluewave/Banner/Banner'
import { AppLayout } from '@/root/modules/bluewave'
import TicketPayment from '@/root/modules/bluewave/TicketPayment/TicketPayment'
import { useGetConfirmationTerms } from '@/root/shared-hooks/sales-static/useGetConfirmationTerms'
import { bluewave } from '@/root/shared-consts/theme'
import BookingProgressBar from '@/root/modules/shared/BookingProgressBar/components/BookingProgressBar/BookingProgressBar'
import { BookingProgressBarContainer } from '@/root/modules/bluewave/BookingProgressBar/BookingProgressBarContainer'

export interface ConfirmTicketsPageProps {
  reservation: Reservation
  companyForm?: boolean
  privatePersonForm?: boolean
}

const ConfirmTicketsPage: FC<ConfirmTicketsPageProps> = ({
  reservation: staticReservation,
  companyForm,
  privatePersonForm,
}) => {
  const { theme } = useConfig()
  const { locale } = useCustomRouter()
  const { getTranslation } = useGetTranslations()
  const { reservation, error, isLoading } = usePrintedTicketData(staticReservation)
  const reservationId = reservation?.reservationId
  const { confirmationTerms } = useGetConfirmationTerms(locale)

  if (isLoading) {
    return (
      <div className="min-w-400">
        <AppLayout>
          <div className="flex justify-center py-40">
            <Loader />
          </div>
        </AppLayout>
      </div>
    )
  }

  if (error || !reservationId) {
    return (
      <div className="min-w-400">
        <AppLayout>
          <div className="m-10 mx-auto flex max-w-7xl flex-col bg-white p-10">
            <Banner
              title={getTranslation('ticketBannerErrorTitle')}
              body={getTranslation('ticketBannerErrorBody')}
              variant="error"
            />
          </div>
        </AppLayout>
      </div>
    )
  }

  if (theme === bluewave) {
    return (
      <div className="min-w-400">
        <AppLayout>
          <div className="soft-lg mx-auto my-10 flex max-w-screen-xl flex-col justify-center bg-white shadow-lg">
            <BookingProgressBarContainer>
              <BookingProgressBar maxReachedStep="select-tickets" />
              {reservation && (
                <TicketPayment
                  ticketData={reservation}
                  privatePersonForm={privatePersonForm}
                  companyForm={companyForm}
                  confirmationTerms={confirmationTerms || []}
                />
              )}
            </BookingProgressBarContainer>
          </div>
        </AppLayout>
      </div>
    )
  }

  return null
}

export default ConfirmTicketsPage
