import React, { FC } from 'react'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { SailPackage } from '@/root/shared-types'
import { useGetRouteSearchBarProps } from '@/root/shared-hooks/useGetRouteSearchBarProps/useGetRouteSearchBarProps'
import { CellContent } from '@/root/modules/bluewave/RouteSearchBar/CellContent/CellContent'
import Dropdown from '@/root/components/bluewave/Dropdown/Dropdown'
import SearchBarCell from '@/root/modules/bluewave/RouteSearchBar/SearchBarCell/SearchBarCell'
import { SearchbarDayPickers } from '@/root/modules/bluewave/RouteSearchBar/SearchbarDayPickers/SearchbarDayPickers'
import ButtonWithoutIcon from '@/root/ui/bluewave/ButtonWithoutIcon/ButtonWithoutIcon'
import CheckboxIcon from '@/root/ui/shared/icons/CheckboxIcon'

interface RouteSearchBarComponentProps {
  sailPackages: SailPackage[]
}

const RouteSearchBar: FC<RouteSearchBarComponentProps> = ({ sailPackages }) => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()

  const {
    handleDateChange,
    selectedDate,
    isRoundTripChecked,
    onRoundTripCheck,
    dropdownProps,
    selectedRoute,
    availableDates,
    onGoToSelectTicket,
    isDayPickerOpened,
    toggleDayPickers,
  } = useGetRouteSearchBarProps(sailPackages)

  return (
    <>
      <section className="flex w-full flex-col rounded-md bg-white lg:mb-6 lg:grid lg:min-h-20 lg:w-full lg:grid-cols-[30%_30%_40%]">
        <SearchBarCell>
          <CellContent
            title={getTranslation('searchBarWhereLabel')}
            titleClassName="text-14px pt-4 text-primary-base capitalize"
            contentClassName="text-sm text-black pb-1"
          >
            <Dropdown
              {...dropdownProps}
              handleOptionSelection={(route) => {
                dropdownProps.handleOptionSelection(route)
                toggleDayPickers()
              }}
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell>
          <CellContent
            title={getTranslation('searchBarWhenLabel')}
            titleClassName="text-14px pt-4 text-primary-base capitalize"
            contentClassName="text-sm text-black pb-1"
          >
            <SearchbarDayPickers
              selectedDate={selectedDate}
              availableDates={availableDates}
              isRoundTrip={isRoundTripChecked}
              onDateChange={handleDateChange}
              titleTo={selectedRoute.name[locale]}
              titleFrom={selectedRoute.extraProps?.backwardsName && selectedRoute.extraProps?.backwardsName[locale]}
              isOpened={isDayPickerOpened}
              toggleDayPickers={toggleDayPickers}
            />
          </CellContent>
        </SearchBarCell>
        <SearchBarCell className="lg:justify-between">
          <CheckboxIcon
            label={getTranslation('searchBarRoundTrip')}
            labelClassName="text-14px pt-4 text-primary-base capitalize"
            checked={isRoundTripChecked}
            variant="success"
            onChange={onRoundTripCheck}
            className="text-neutral-dark flex h-14 items-center gap-3 text-lg font-bold uppercase lg:grid lg:h-fit lg:shrink-0 lg:grid-rows-[40px_minmax(40px,auto)] lg:gap-0 [&>*:last-child]:order-first lg:[&>*:last-child]:pt-4 rtl:lg:[&>*:last-child]:pe-4 ltr:[&>*:nth-child(2)]:pb-1"
            disabled={!selectedRoute.extraProps?.backwardsSailPackage}
          />
          <ButtonWithoutIcon
            className="hidden min-h-20 w-auto rounded-none rounded-e-md border text-xl lg:block"
            disabled={Boolean(!selectedDate.to)}
            onClick={onGoToSelectTicket}
          >
            {getTranslation('searchBarGetTickets')}
          </ButtonWithoutIcon>
        </SearchBarCell>
      </section>
      <ButtonWithoutIcon
        variant="success"
        className="my-6 h-fit w-full border text-center text-xl lg:hidden"
        disabled={Boolean(!selectedDate.to)}
        onClick={onGoToSelectTicket}
      >
        {getTranslation('searchBarGetTickets')}
      </ButtonWithoutIcon>
    </>
  )
}

export default RouteSearchBar
