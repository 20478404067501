import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks/useGetTranslations/useGetTranslations'
import { ConfirmationTerm } from '@/root/shared-types/reservation/reservation-types'

import { getStyledHTML } from '@/root/utils/getStyledHTML'
import { Banner, RouteCheckbox } from '@/root/ui/bluewave'
import { bluewave } from '@/root/shared-consts/theme'

interface TicketPaymentAgreeBoxProps {
  confirmationTerms: ConfirmationTerm[]
}

const extractListItems = (html: string): string[] => {
  return html.match(/<li>(.*?)<\/li>/g)?.map((item) => item.replace(/<\/?li>/g, '')) || []
}

const TicketPaymentAgreeBox: FC<TicketPaymentAgreeBoxProps> = ({ confirmationTerms }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <Banner variant="warning" className="bg-warning-very-light border-warning-dark border-2 p-8">
      <div className="mb-5 flex items-start justify-between text-black">
        <RouteCheckbox
          label={getTranslation('paymentAgreeTitle').toUpperCase()}
          className="text-secondary items-start font-semibold"
        />
      </div>
      <ul className="pl-10 text-start font-normal">
        {confirmationTerms.map((term) => {
          const items = extractListItems(term.body)
          return items.map((item, id) => (
            <li key={id} className="mb-1 flex items-start text-black">
              <div className="bg-base mt-1.5 size-2 rounded-full pr-2"></div>
              <div
                className="pl-2 text-black"
                dangerouslySetInnerHTML={{
                  __html: getStyledHTML({ body: item, theme: bluewave }),
                }}
              />
            </li>
          ))
        })}
      </ul>
    </Banner>
  )
}

export default TicketPaymentAgreeBox
