import { FC } from 'react'
import { ReservationItem } from '@/root/shared-types/reservation/reservation-types'
import { TicketPaymentRouteAndDate } from './TicketPaymentRouteAndDate'
import TicketPaymentTable from './TicketPaymentTable'

export interface TicketPaymentPartProps {
  reservationId: number
  isRoundTrip: boolean
  departurePort: string
  arrivalPort: string
  items: ReservationItem[]
  departure?: string
}

const TicketPaymentPart: FC<TicketPaymentPartProps> = ({
  reservationId,
  items,
  departure,
  departurePort,
  arrivalPort,
}) => {
  return (
    <div className="grid grid-cols-1 text-xl md:grid-cols-[1fr,2fr]">
      <TicketPaymentRouteAndDate departure={departure ?? ''} arrivalPort={arrivalPort} departurePort={departurePort} />
      <TicketPaymentTable items={items} reservationId={reservationId} />
    </div>
  )
}

export default TicketPaymentPart
