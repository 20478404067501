import { FC } from 'react'
import { dateToFormat } from '@/root/shared-helpers/date/dateHelpers'
import { dateFormats } from '@/root/shared-consts/dates/dateFormats'
import { useCustomRouter } from '@/root/shared-hooks'
import { ArrowIconBlue } from '@/root/ui/shared/icons/ArrowIconBlue'

export interface TicketInfoRouteProps {
  departure: string
  departurePort: string
  arrivalPort: string
}

const RouteTitle: FC<TicketInfoRouteProps> = ({ arrivalPort, departurePort }) => {
  return (
    <div className="whitespace-nowrap text-lg">
      <p data-testid="departure-port" className="text-m pb-16 font-semibold text-primary-base">
        {departurePort}
      </p>
      <p data-testid="arrival-port" className="text-m font-semibold text-primary-base">
        {arrivalPort}
      </p>
    </div>
  )
}

const DepartureDate: FC<{ departure: string }> = ({ departure }) => {
  const { locale } = useCustomRouter()
  return (
    <div className="ml-8 whitespace-pre-line text-end text-lg sm:text-start">
      {departure && (
        <p data-testid="departure-date" className="text-m font-semibold text-primary-base">
          {dateToFormat(departure, dateFormats.BIG_FORMAT, locale).toUpperCase()}
        </p>
      )}
    </div>
  )
}

export const TicketPaymentRouteAndDate: FC<TicketInfoRouteProps> = ({ arrivalPort, departurePort, departure }) => {
  return (
    <>
      <div className="xs:flex-raw m-8 flex justify-between text-xl md:ml-4">
        <div className="relative flex flex-col">
          <RouteTitle arrivalPort={arrivalPort} departurePort={departurePort} departure={departure} />
          <div className="absolute left-5 top-14 rotate-90">
            <ArrowIconBlue />
          </div>
        </div>
        <div className="xs:text-end">
          <DepartureDate departure={departure} />
        </div>
      </div>
    </>
  )
}

export default TicketPaymentRouteAndDate
