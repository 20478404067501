export const vehicleAttributes = {
  CARRIER_CODE: 'CARRIER_CODE',
  CONSIGNEE: 'CONSIGNEE',
  GOODS_TYPE: 'GOODS_TYPE',
  MERCHANDISE_DESCRIPTION: 'MERCHANDISE_DESCRIPTION',
  SHIPPER: 'SHIPPER',
}

export const driverAttributes = {
  DRIVER_FILE: 'DRIVER_FILE',
  DRIVER_LICENSE_NUMBER: 'DRIVER_LICENSE_NUMBER',
  TRANSPORTER_FILE: 'TRANSPORTER_FILE',
}

export const driverPersonalInfo = {
  AGE_CATEGORY: 'ageCategory',
  BIRTH_PLACE: 'birthPlace',
  BIRTHDAY: 'birthday',
  CITIZENSHIP: 'citizenship',
  COMMENTS: 'comments',
  FIRST_NAME: 'firstName',
  GENDER: 'gender',
  LAST_NAME: 'lastName',
  PERSONAL_IDENTIFICATION_NUMBER: 'personalIdentificationNumber',
  TRAVEL_DOCUMENT_NUMBER: 'travelDocumentNumber',
  TRAVEL_DOCUMENT_EXPIRE_DATE: 'travelDocumentExpireDate',
}

export const documentModificationMode = {
  [driverPersonalInfo.TRAVEL_DOCUMENT_NUMBER]: 'number',
  [driverPersonalInfo.TRAVEL_DOCUMENT_EXPIRE_DATE]: 'expireDate',
}
