import { axios } from '@/configs'
import useSWR from 'swr/immutable'
import { Goods } from '@/root/shared-types'

export function useGetGoodsTypes() {
  const getGoodsTypes = async () => {
    const { data } = await axios<Goods>({
      url: `goods-types`,
      method: 'get',
    })
    return data
  }

  const { data } = useSWR('app-config', getGoodsTypes)
  return data ? data.items.map(({ code, title }) => ({ text: code, value: title })) : []
}
