import { useReservation } from '@/root/shared-hooks'
import {
  getCustomerInfoFromReservationOwner,
  getDepartureAndArrivalDataFromReservation,
} from '@/root/shared-helpers/reservation/reservation-helpers'
import { DateTime } from 'luxon'
import { AdditionalInfoData } from '@/root/shared-types'
import { Attributes } from '@/root/shared-types/additionalInfo/additional-info-types'
import { useGetVehiclesAndDriverInfoArraysFromReservation } from '@/root/shared-hooks/additional-info/useGetVehiclesAndDriverInfoArraysFromReservation'

export function useAdditionalInfoData(): {
  error: Error
  isLoading: boolean
  additionalInfoData?: AdditionalInfoData
} {
  const { reservation, error, isLoading } = useReservation()
  const attributes = useGetVehiclesAndDriverInfoArraysFromReservation(reservation)
  if (reservation) {
    const { reservationOwner, reservationId } = reservation

    const { departureThere, departurePortThere, arrivalPortThere } =
      getDepartureAndArrivalDataFromReservation(reservation)
    const departureValue = departureThere ? DateTime.fromISO(departureThere).toFormat('dd MMMM yyyy, HH:mm') : null

    return {
      error,
      isLoading,
      additionalInfoData: {
        customerInfo: getCustomerInfoFromReservationOwner(reservationOwner),
        routInfo: {
          departure: departureValue,
          departurePort: departurePortThere,
          arrivalPort: arrivalPortThere,
        },
        attributes: attributes as Attributes[],
        reservationId,
      },
    }
  }
  return { error, isLoading, additionalInfoData: reservation }
}
