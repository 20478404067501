import { FC } from 'react'
import { ConfirmationTerm, TicketData } from '@/root/shared-types/reservation/reservation-types'
import { ContentContainer } from '@/root/modules/bluewave/ContentPage/components/ContentContainer/ContentContainer'
import { globalFont } from '@/root/modules/bluewave/AppLayout/assets/globalFonts/globalFonts'
import TicketPaymentPart from '@/root/modules/bluewave/TicketPayment/TicketPaymentPart'
import TotalToPay from '@/root/modules/bluewave/TicketPayment/TicketPaymentTotalToPay'
import TicketPaymentAgreeBox from '@/root/modules/bluewave/TicketPayment/TicketPaymentAgreeBox'
import { Button } from '@/root/ui/bluewave'
import { useCustomRouter, useGetTranslations } from '@/root/shared-hooks'
import { CustomerInfoIcon } from '@/root/ui/shared/icons/CustomerInfoIcon'
import { Divider } from '@/root/ui/shared/Divider/Divider'
import CompanyForm from '@/root/pages/bluewave/CustomerInfo/CompanyForm'
import PersonForm from '@/root/pages/bluewave/CustomerInfo/PersonForm'
import Tabs from '@/root/pages/bluewave/CustomerInfo/Tabs'

interface TicketProps {
  ticketData: TicketData
  confirmationTerms?: ConfirmationTerm[]
  companyForm?: boolean
  privatePersonForm?: boolean
}
const TicketPayment: FC<TicketProps> = ({ ticketData, confirmationTerms, privatePersonForm, companyForm }) => {
  const { getTranslation } = useGetTranslations()
  const { locale } = useCustomRouter()
  if (!ticketData) {
    return <div>No ticket data available</div>
  }

  const { there, back, isRoundTrip, reservationId, amount } = ticketData

  return (
    <div className={`${globalFont.className} flex flex-col`}>
      <ContentContainer className="min-w-400">
        <div className="border-base-normal-light rounded-3xl border">
          <div className="ml-30 mr-30 bg-white">
            <div className="pt-27 mt-10 flex flex-row items-baseline pl-9 pr-7">
              <CustomerInfoIcon />
              <h1 className="pt-27 text-primary-base pl-10 text-3xl font-semibold md:text-center">
                {getTranslation('confirmPageTitle')}
              </h1>
            </div>
            <Tabs companyForm={companyForm} privatePersonForm={privatePersonForm}>
              <CompanyForm
                label={getTranslation('confirmPageCompanyTab')}
                id="Invoice to Company"
                reservationId={reservationId}
                locale={locale}
              />
              <PersonForm
                label={getTranslation('confirmPagePersonTab')}
                id="Private person"
                reservationId={reservationId}
              />
            </Tabs>
          </div>
          <div className="bg-base-normal-light m-4 h-px grow"></div>
          <div className="grid grid-cols-1 text-xl">
            <TicketPaymentPart reservationId={reservationId} isRoundTrip={isRoundTrip} {...there} />
            {isRoundTrip && (
              <div className="relative">
                <Divider variant={'default'} />
                <TicketPaymentPart reservationId={reservationId} isRoundTrip={isRoundTrip} {...back} />
              </div>
            )}
          </div>
          <div className="bg-base-normal-light m-4 h-px grow"></div>
          <TotalToPay amount={amount} />
        </div>
        {confirmationTerms && <TicketPaymentAgreeBox confirmationTerms={confirmationTerms} />}
        <div className="mt-4 flex w-full flex-col justify-between gap-12 md:flex-row">
          <Button
            label={getTranslation('paymentPayWithCardButton').toUpperCase()}
            variant="success"
            iconType="PayWithCard"
            className="flex-1"
          />
          <Button
            label={getTranslation('paymentBankTransferButton').toUpperCase()}
            variant="success"
            iconType="BankTransfer"
            className="flex-1"
          />
        </div>
      </ContentContainer>
    </div>
  )
}

export default TicketPayment
