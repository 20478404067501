import React, { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { RouteCheckboxWhiteIcon } from './RouteCheckboxWhiteIcon'
import { CheckBoxCheckedIcon } from './CheckBoxCheckedIcon'
import { CheckBoxNotCheckedIcon } from './CheckBoxNotCheckedIcon'

type CheckboxVariant = 'error' | 'success'

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  label: string
  checked: boolean
  disabled?: boolean
  variant?: CheckboxVariant
  labelClassName?: string
}

const CheckboxIcon: FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
  variant = 'error',
  className,
  labelClassName,
}) => {
  const checkboxVariants = {
    error: checked ? <CheckBoxCheckedIcon /> : <CheckBoxNotCheckedIcon />,
    success: (
      <div
        className={cn('w-5 h-5 border-2 rounded-sm flex items-center justify-center transition duration-300', {
          'bg-secondary shadow-lg': checked,
          'border-neutral-normal-lighter': disabled,
          'border-secondary cursor-pointer': !disabled,
        })}
      >
        <RouteCheckboxWhiteIcon />
      </div>
    ),
  }

  return (
    <label
      className={cn(
        {
          'cursor-not-allowed text-neutral-normal-lighter fill-neutral-normal-lighter': disabled,
          'cursor-pointer fill-error': !disabled,
        },
        'flex gap-3',
        className
      )}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        className="absolute appearance-none opacity-0"
        onChange={onChange}
      />
      <div>{checkboxVariants[variant]}</div>
      <p className={cn('mb-2', labelClassName)}>{label}</p>
    </label>
  )
}

export default CheckboxIcon
