import { FC } from 'react'
import Link from 'next/link'
import { useGetTranslations } from '@/root/shared-hooks'

export const DeletedBookingPage: FC = () => {
  const { getTranslation } = useGetTranslations()

  return (
    <div className="m-10 mx-auto flex h-4/5 max-w-7xl flex-col items-center justify-center gap-5 bg-white p-10">
      <div className="text-center">
        <h1 className="inline text-xl">{getTranslation('ticketDeletedBookingTitlePart1')}</h1>
        <Link className="text-xl underline" href="/">
          {getTranslation('ticketDeletedBookingTitleLink')}
        </Link>
        <h1 className="inline text-xl">{getTranslation('ticketDeletedBookingTitlePart2')}</h1>
      </div>
      <Link className="w-fit rounded-md bg-secondary px-10 py-2 font-bold text-white" href="/">
        {getTranslation('ticketDeletedBookingButton')}
      </Link>
    </div>
  )
}
