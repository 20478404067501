import { FC } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { ReservationItem } from '@/root/shared-types/reservation/reservation-types'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'

interface TicketPaymentTableProps {
  items: ReservationItem[]
  reservationId: number
  formatMoney?: (price: number, hideCurrency?: boolean) => string
}

interface TicketInfoTableProps {
  ticketType: string
  quantity: number
  price: number
  tax: number
  subtotal: number
}

const TicketPaymentTableHeader: FC = () => {
  const { getTranslation } = useGetTranslations()
  return (
    <div className="grid grid-cols-2 pb-5 sm:grid-cols-[3fr,1fr,1fr,1fr,1fr]">
      <p className="min-w-130 text-base-normal-light text-sm font-normal">
        {getTranslation('priceCategoryTranslation')}
      </p>
      <p className="text-base-normal-light hidden text-center text-sm font-normal sm:block">
        {getTranslation('quantity')}
      </p>
      <p className="text-base-normal-light hidden text-center text-sm font-normal sm:block">
        {getTranslation('price')}
      </p>
      <p className="text-base-normal-light hidden text-center text-sm font-normal sm:block">{getTranslation('tax')}</p>
      <p className="text-base-normal-light text-end text-sm font-normal">{getTranslation('subtotal')}</p>
    </div>
  )
}

const TicketPaymentItem: FC<TicketInfoTableProps> = ({ ticketType, quantity, price, tax, subtotal }) => {
  const { formatMoney } = useAppConfig()
  return (
    <div className="grid grid-cols-2 pb-5 text-xl sm:grid-cols-[3fr,1fr,1fr,1fr,1fr]">
      <p className="min-w-130 text-sm">{ticketType}</p>
      <p className="hidden text-center text-sm sm:block">{quantity}</p>
      <p className="hidden text-center text-sm sm:block">{formatMoney(price, true)}</p>
      <p className="hidden text-center text-sm sm:block">{formatMoney(tax, true)}</p>
      <p className="text-end text-sm">{formatMoney(subtotal, true)}</p>
    </div>
  )
}

const TicketPaymentTable: FC<TicketPaymentTableProps> = ({ items, reservationId }) => {
  if (!items || items.length === 0) {
    return <div>No items available</div>
  }

  const tableOptions = items.map((item) => {
    const { priceCategoryTranslation: ticketType, quantity, price, taxAmount: tax } = item
    const subtotal = (price + tax) * quantity

    return (
      <TicketPaymentItem
        key={`${reservationId}-${ticketType}`}
        ticketType={ticketType}
        quantity={quantity}
        price={price}
        tax={tax}
        subtotal={subtotal}
      />
    )
  })

  return (
    <div className="ml-30 m-8">
      <TicketPaymentTableHeader />
      <div>{tableOptions}</div>
    </div>
  )
}

export default TicketPaymentTable
