import AppLayout from '../../../modules/bluewave/AppLayout/components/AppLayout/AppLayout'
import Banner from '../../../ui/bluewave/Banner/Banner'
import { AdditionalFields } from '@/root/modules/bluewave'
import { useAdditionalInfoData } from '@/root/shared-hooks/additional-info/useAdditionalInfoData'
import { Loader } from '@/root/ui/bluewave'
import { useGetTranslations } from '@/root/shared-hooks'

const AdditionalInfoPage = () => {
  const { getTranslation } = useGetTranslations()

  const { additionalInfoData, isLoading, error } = useAdditionalInfoData()

  if (isLoading) {
    return (
      <div className="flex justify-center py-40">
        <Loader />
      </div>
    )
  }
  if (additionalInfoData) {
    const { attributes } = additionalInfoData
    if (!attributes) {
      return null
    }
    const allSectionsFilled = attributes?.every(
      ({ vehicleInfo, driverInfo }) =>
        vehicleInfo.attributes.every((atr) => atr.value) &&
        (driverInfo === null
          ? true
          : driverInfo?.every((dI) =>
              [...dI.attributes, ...dI.additionalInfo].every((atr) => atr.value || atr.placeholder)
            ))
    )

    return (
      <AppLayout>
        <div className="m-10 mx-auto flex max-w-7xl flex-col gap-5 bg-white p-2 md:p-10 ">
          {allSectionsFilled ? (
            <Banner title={getTranslation('additionalInfoBannerInfoFilled')} variant="success" />
          ) : (
            <Banner title={getTranslation('additionalInfoBannerInfoNotFilled')} variant="warning" />
          )}
          <AdditionalFields additionalInfoData={additionalInfoData} allSectionsFilled={!allSectionsFilled} />
        </div>
      </AppLayout>
    )
  }
  if (error) {
    return null
  }
}

export default AdditionalInfoPage
