import { FC } from 'react'
import { Banner } from '@/root/ui/bluewave'
import { AvailableTheme, getMessages } from '@/lang'
import { useIntl } from 'react-intl'
import { useConfig } from '@/root/shared-hooks'
import Link from 'next/link'
import { WarningIcon } from '@/root/ui/shared/icons/WarningIcon'

interface PaidTicketBannerProps {
  token: string | string[] | undefined
  displayGoToAdditionalInfo?: boolean
}

export const PaidTicketBanner: FC<PaidTicketBannerProps> = ({ displayGoToAdditionalInfo = false, token }) => {
  const { formatMessage } = useIntl()
  const { theme } = useConfig()
  const currentMessages = getMessages(theme as AvailableTheme)
  return (
    <div>
      <Banner
        variant="neutral"
        title={formatMessage(currentMessages.ticketBannerTitle)}
        body={formatMessage(currentMessages.ticketBannerBody)}
      />
      {displayGoToAdditionalInfo && (
        <Banner variant="warning">
          <div className="flex items-center">
            <span className="hidden md:block">
              <WarningIcon />
            </span>
            <div className="w-full">
              <h2 className="mb-2 text-2xl">Additional data is required to be filled before Check-in!</h2>
              <Link
                className="rounded-md bg-secondary px-10 py-2 font-bold text-white"
                href={`/ticket/additional-info/${token}`}
                target="_blank"
              >
                REVIEW REQUIRED DATA
              </Link>
            </div>
          </div>
        </Banner>
      )}
    </div>
  )
}
