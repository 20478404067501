import { FC } from 'react'
import AppLayout from '@/root/modules/bluewave/AppLayout/components/AppLayout/AppLayout'
import { useCustomRouter, useGetTranslations, usePrintedTicketData } from '@/root/shared-hooks'
import Banner from '@/root/ui/bluewave/Banner/Banner'
import { PrintedTicket, TicketActionButtons } from '@/root/modules/bluewave'
import { Loader } from '@/root/ui/bluewave'

import { PaidTicketBanner } from '@/root/modules/bluewave/PrintedTicket/components/PaidTicketBanner/PaidTicketBanner'
import { BookedTicketBanner } from '@/root/modules/bluewave/PrintedTicket/components/BookedTicketBanner/BookedTicketBanner'
import { getExpireAtDateFromISOString } from '@/root/shared-helpers/reservation/reservation-helpers'
import { DeletedBookingPage } from '@/root/modules/bluewave/PrintedTicket/components/DeletedBookingPage/DeletedBookingPage'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'
import { TicketPageProps } from '@/pages/ticket/[reservationToken]'
import { DateTime } from 'luxon'

export const TicketPage: FC<TicketPageProps> = ({ reservation: staticReservation }) => {
  const { getTranslation } = useGetTranslations()
  const { reservation, error, isLoading } = usePrintedTicketData(staticReservation)

  const {
    locale,
    query: { reservationToken },
  } = useCustomRouter()

  const { defaultTimezone, features } = useAppConfig()

  const isTickedExpired = DateTime.fromISO(reservation?.there.departure as string).diffNow().milliseconds < 0
  const displayGoToAdditionalInfo = Boolean(
    features?.reservationAdditionalAttributes && reservation?.there.numOfCars && !isTickedExpired
  )

  const renderTicketContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center py-40">
          <Loader />
        </div>
      )
    }
    if (error) {
      return (
        <div className="m-10 mx-auto flex max-w-7xl flex-col bg-white p-10">
          <Banner
            title={getTranslation('ticketBannerErrorTitle')}
            body={getTranslation('ticketBannerErrorBody')}
            variant="error"
          />
        </div>
      )
    }
    if (reservation) {
      const { status } = reservation
      const isReservationPaid = status === 'OK'
      const isReservationDeleted = status === 'DL'
      const isReservationBooked = reservation.status === 'BOOKED'

      if (isReservationDeleted) {
        return <DeletedBookingPage />
      }

      const expireAt =
        defaultTimezone && reservation.expireAt
          ? getExpireAtDateFromISOString({
              isoDate: reservation.expireAt,
              locale: locale,
              defaultTimezone,
            })
          : null

      return (
        <div className="m-10 mx-auto flex  max-w-7xl flex-col gap-y-6 bg-white p-3 md:px-16 md:py-10 ">
          {isReservationPaid && (
            <>
              <PaidTicketBanner displayGoToAdditionalInfo={displayGoToAdditionalInfo} token={reservationToken} />
              {reservation.token && (
                <TicketActionButtons reservationId={reservation.reservationId} reservationToken={reservation.token} />
              )}
            </>
          )}

          {isReservationBooked && expireAt && <BookedTicketBanner expireAt={expireAt} />}
          <PrintedTicket ticketData={reservation} />
        </div>
      )
    }
    return null
  }

  return <AppLayout>{renderTicketContent()}</AppLayout>
}

export default TicketPage
