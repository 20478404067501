import { FC, PropsWithChildren, ButtonHTMLAttributes } from 'react'
import cn from 'classnames'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'success' | 'default'
  label?: string
}

const buttonVariants = {
  success: 'font-bold bg-secondary darken',
  default: 'bg-neutral-normal-lighter',
}

const ButtonWithoutIcon: FC<PropsWithChildren<ButtonProps>> = ({ variant = 'success', className, ...props }) => {
  return (
    <button
      className={cn(
        'text-white text-center items-center border-none py-4 px-8 rounded-lg disabled:bg-adm-gray-450 w-auto gap-2',
        buttonVariants[variant],
        className
      )}
      {...props}
    ></button>
  )
}

export default ButtonWithoutIcon
