import { Reservation } from '@/root/shared-types'
import {
  driverAttributes,
  driverPersonalInfo,
  vehicleAttributes,
} from '@/root/shared-consts/additional-info/additional-info'
import {
  capitalizeFirstLetter,
  getPersonalPassengerData,
  getValueFromAttribute,
} from '@/root/shared-helpers/Additional-info/additional-info-helpers'
import { useGetGoodsTypes } from '@/root/shared-hooks/additional-info/useGetGoodsTypes'
import { useGetTranslations } from '@/root/shared-hooks'

import { getNumOfPriceCategorySubTypesFromItems } from '@/root/shared-helpers'

export const useGetVehiclesAndDriverInfoArraysFromReservation = (reservation?: Reservation) => {
  const { getTranslation } = useGetTranslations()

  const items = useGetGoodsTypes()
  if (!reservation) {
    return null
  }
  const { vehicles, guests = [] } = reservation
  const { numOfPassengers } = getNumOfPriceCategorySubTypesFromItems(reservation.items)

  return vehicles?.map((vehicle) => {
    const driverInfo =
      numOfPassengers > 0
        ? guests
            .filter((guest) => guest.seqN === vehicle.guestSeqN)
            .map((driver) => ({
              seqN: driver.seqN,
              attributes: [
                {
                  title: getTranslation('driverLicenseNumber'),
                  id: driverAttributes.DRIVER_LICENSE_NUMBER,
                  type: 'text',
                  value: getValueFromAttribute(driver.attributes, driverAttributes.DRIVER_LICENSE_NUMBER),
                },
                {
                  title: getTranslation('driverLicenseDocument'),
                  id: driverAttributes.DRIVER_FILE,
                  type: 'file',
                  placeholder: getValueFromAttribute(driver.attributes, driverAttributes.DRIVER_FILE),
                },
                {
                  title: getTranslation('driverTransporterFile'),
                  id: driverAttributes.TRANSPORTER_FILE,
                  type: 'file',
                  placeholder: getValueFromAttribute(driver.attributes, driverAttributes.TRANSPORTER_FILE),
                },
              ],
              additionalInfo: [
                {
                  title: getTranslation('firstName'),
                  id: driverPersonalInfo.FIRST_NAME,
                  type: 'text',
                  value: capitalizeFirstLetter(getPersonalPassengerData('firstName', driver)),
                  editMode: Boolean(!getPersonalPassengerData('firstName', driver)),
                  isAttribute: false,
                },
                {
                  title: getTranslation('lastName'),
                  id: driverPersonalInfo.LAST_NAME,
                  type: 'text',
                  value: capitalizeFirstLetter(getPersonalPassengerData('lastName', driver)),
                  editMode: Boolean(!getPersonalPassengerData('lastName', driver)),
                  isAttribute: false,
                },
                {
                  title: getTranslation('birthday'),
                  id: driverPersonalInfo.BIRTHDAY,
                  type: 'date',
                  value: getPersonalPassengerData('birthday', driver),
                  editMode: Boolean(!getPersonalPassengerData('birthday', driver)),
                  isAttribute: false,
                },
                {
                  title: getTranslation('birthPlace'),
                  id: driverPersonalInfo.BIRTH_PLACE,
                  type: 'text',
                  value: capitalizeFirstLetter(getPersonalPassengerData('birthPlace', driver)),
                  editMode: Boolean(!getPersonalPassengerData('birthPlace', driver)),
                  isAttribute: false,
                },
                {
                  title: getTranslation('passportNr'),
                  id: driverPersonalInfo.TRAVEL_DOCUMENT_NUMBER,
                  type: 'text',
                  value: getPersonalPassengerData('number', driver),
                  editMode: Boolean(!getPersonalPassengerData('number', driver)),
                  isAttribute: false,
                },
                {
                  title: getTranslation('passportExpirationDate'),
                  id: driverPersonalInfo.TRAVEL_DOCUMENT_EXPIRE_DATE,
                  type: 'date',
                  value: getPersonalPassengerData('expireDate', driver),
                  editMode: Boolean(!getPersonalPassengerData('expireDate', driver)),
                  isAttribute: false,
                },
              ],
              info: {
                type: 'driver',
                data: driver.fullName,
              },
            }))
        : null
    return {
      vehicleInfo: {
        seqN: vehicle.seqN,
        info: {
          type: 'car',
          data: '11122236',
        },
        attributes: [
          {
            title: getTranslation('vehicleCarrierCode'),
            id: vehicleAttributes.CARRIER_CODE,
            type: 'text',
            value: getValueFromAttribute(vehicle.attributes, vehicleAttributes.CARRIER_CODE),
          },
          {
            title: getTranslation('vehicleShipper'),
            id: vehicleAttributes.SHIPPER,
            type: 'text',
            value: getValueFromAttribute(vehicle.attributes, vehicleAttributes.SHIPPER),
          },
          {
            title: getTranslation('vehicleConsignee'),
            id: vehicleAttributes.CONSIGNEE,
            type: 'text',
            value: getValueFromAttribute(vehicle.attributes, vehicleAttributes.CONSIGNEE),
          },
          {
            title: getTranslation('vehicleGoodsType'),
            id: vehicleAttributes.GOODS_TYPE,
            type: 'select',
            value: getValueFromAttribute(vehicle.attributes, vehicleAttributes.GOODS_TYPE),
            options: items,
          },
          {
            title: getTranslation('vehicleMerchandiseDescription'),
            id: vehicleAttributes.MERCHANDISE_DESCRIPTION,
            type: 'textarea',
            value: getValueFromAttribute(vehicle.attributes, vehicleAttributes.MERCHANDISE_DESCRIPTION),
          },
        ],
        additionalInfo: [
          {
            title: getTranslation('carLength'),
            id: 'LENGTH',
            type: 'text',
            value: `${vehicle.lengthInCm / 100} m`,
            editable: false,
          },
          {
            title: getTranslation('carWidth'),
            id: 'WIDTH',
            type: 'text',
            value: `${vehicle.widthInCm / 100} m`,
            editable: false,
          },
          {
            title: getTranslation('carHeight'),
            id: 'CAR_HEIGHT',
            type: 'text',
            value: `${vehicle.heightInCm / 100} m`,
            editable: false,
          },
          {
            title: getTranslation('carWeight'),
            id: 'CAR_WEIGHT',
            type: 'text',
            value: `${vehicle.weightInKg / 1000} t`,
            editable: false,
          },
        ],
      },
      driverInfo,
    }
  })
}
