import React, { FC } from 'react'
import cn from 'classnames'

interface DatePlaceholderProps {
  placeholder: string
  date?: string
  truncate?: boolean
  displaySeparator?: boolean
}

export const DatePlaceholder: FC<DatePlaceholderProps> = ({ date, placeholder, truncate, displaySeparator }) => (
  <p
    className={cn('whitespace-nowrap cursor-pointer max-w-36', {
      'text-neutral-medium-lighter xs:max-w-48 sm:max-w-72': !date,
      'xxs:max-w-44 lg:max-w-36 truncate': truncate,
    })}
  >
    {`${displaySeparator ? '-' : ''} ${date || placeholder}`}
  </p>
)
