import React, { FC, RefObject, useState, useEffect, useRef, ChangeEvent } from 'react'
import { DropdownOption } from '@/root/shared-types'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { useConfig } from '@/root/shared-hooks'

interface RouteDropdownProps {
  isOpen: boolean
  options: DropdownOption[]
  handleToggleDropdown: () => void
  handleOptionSelection: (route: DropdownOption) => void
  dropdownRef: RefObject<HTMLDivElement>
  placeHolder: string
  selectedOption?: DropdownOption
  testId?: string
}

const Dropdown: FC<RouteDropdownProps> = ({
  isOpen,
  selectedOption,
  options,
  handleToggleDropdown,
  handleOptionSelection,
  dropdownRef,
  placeHolder,
  testId,
}) => {
  const { locale = 'en' } = useRouter()
  const { theme } = useConfig()
  const [inputValue, setInputValue] = useState<string>('')
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)
  const optionRefs = useRef<(HTMLParagraphElement | null)[]>([])
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
    autoResizeTextarea(e.target)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!isOpen) return

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0))
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1))
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (highlightedIndex >= 0) {
        handleOptionSelection(filteredOptions[highlightedIndex])
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      handleToggleDropdown()
    }
  }

  useEffect(() => {
    if (highlightedIndex >= 0 && optionRefs.current[highlightedIndex]) {
      optionRefs.current[highlightedIndex]?.scrollIntoView({
        block: 'nearest',
      })
    }
  }, [highlightedIndex])

  const filteredOptions = options.filter((option) =>
    option.name[locale].toLowerCase().includes(inputValue.toLowerCase())
  )

  useEffect(() => {
    if (!isOpen) {
      setInputValue(selectedOption?.name[locale] || '')
      setHighlightedIndex(-1)
    }
  }, [locale, isOpen, selectedOption])

  useEffect(() => {
    if (textareaRef.current) {
      autoResizeTextarea(textareaRef.current)
    }
  }, [inputValue])

  const autoResizeTextarea = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  return (
    <div className="relative w-full ps-5" ref={dropdownRef}>
      <textarea
        ref={textareaRef}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeHolder}
        onClick={() => {
          setInputValue('')
          handleToggleDropdown()
        }}
        className="placeholder:text-neutral-medium-lighter flex w-full cursor-text resize-none items-center text-base outline-none"
        readOnly={!isOpen}
        onKeyDown={handleKeyDown}
        rows={1}
      />

      {isOpen && (
        <div
          className="absolute start-0 z-10 max-h-96 w-full overflow-y-scroll bg-white pt-4 text-start shadow-md"
          data-testid={testId}
        >
          {filteredOptions.map((option, index) => (
            <p
              key={option.id}
              ref={(el) => {
                optionRefs.current[index] = el
              }}
              className={cn(
                'min-h-10 w-full cursor-pointer py-2 ps-5 text-tertiary-darker transition-colors duration-300',
                {
                  'bg-base-lightest': highlightedIndex === index,
                  'hover:bg-base-lightest': highlightedIndex !== index && theme === 'admaritime',
                  'hover:bg-tertiary-ultra-light': highlightedIndex !== index && theme === 'bluewave',
                }
              )}
              onClick={() => handleOptionSelection(option)}
            >
              {option.name[locale]}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default Dropdown
