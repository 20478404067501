import { Slug } from '@/root/shared-types'
import { useGetPageContent } from '@/root/shared-hooks/sales-static/usePages'
import { StaticPage } from '@/root/modules/bluewave/ContentPage/components/StaticPage/StaticPage'
import { AppLayout } from '@/root/modules/bluewave'
import { useCustomRouter } from '@/root/shared-hooks'

export const ContentPage = () => {
  const {
    query: { page },
  } = useCustomRouter()

  const { pageContent } = useGetPageContent(page as Slug, 'bluewave')

  return <AppLayout>{pageContent?.page && <StaticPage page={pageContent.page} slug={pageContent.slug} />}</AppLayout>
}
