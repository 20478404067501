import { FC } from 'react'
import Banner from '../../../../../ui/bluewave/Banner/Banner'
import { useGetTranslations } from '@/root/shared-hooks'

interface BookedTicketBannerProps {
  expireAt: string
}
export const BookedTicketBanner: FC<BookedTicketBannerProps> = ({ expireAt }) => {
  const { getTranslation } = useGetTranslations()

  return (
    <Banner
      title={getTranslation('ticketBookingAwaitingPaymentTitle')}
      body={`${getTranslation('ticketBookingAwaitingPaymentBodyPart1')} ${expireAt}, ${getTranslation(
        'ticketBookingAwaitingPaymentBodyPart2'
      )} `}
      variant="warning"
    />
  )
}
