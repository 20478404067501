import { FC } from 'react'
import { PageProps } from '@/root/shared-types'
import { StaticContentProvider } from '@/root/shared-providers'
import AppLayout from '@/root/modules/bluewave/AppLayout/components/AppLayout/AppLayout'
import RouteSearchBar from '@/root/modules/bluewave/RouteSearchBar/RouteSearchBar/RouteSearchBar'
import { RouteSearchBarContainer } from '@/root/modules/bluewave/RouteSearchBar/RouteSearchBar/RouteSearchBarContainer'
import { useGetTranslations } from '@/root/shared-hooks'

const MainPage: FC<PageProps> = ({ footerItems, menuItems, sailPackages }) => {
  const { getTranslation } = useGetTranslations()
  return (
    <StaticContentProvider
      value={{
        staticFooterItems: footerItems,
        staticMenuItems: menuItems,
      }}
    >
      <AppLayout>
        <div className="flex items-center justify-center">
          <RouteSearchBarContainer>
            <h1 className="mb-16 mt-12 flex h-28 w-full items-center justify-center text-center text-4xl font-bold text-white">
              {getTranslation('searchBarWhenTitle').toUpperCase()}
            </h1>
            {sailPackages && <RouteSearchBar sailPackages={sailPackages} />}
          </RouteSearchBarContainer>
        </div>
      </AppLayout>
    </StaticContentProvider>
  )
}

export default MainPage
