import { useGetTranslations } from '@/root/shared-hooks/useGetTranslations/useGetTranslations'
import React, { FC } from 'react'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'

interface TotalToPayProps {
  amount: number
}

const TotalToPay: FC<TotalToPayProps> = ({ amount }) => {
  const { getTranslation } = useGetTranslations()
  const { formatMoney } = useAppConfig()

  return (
    <div className="text-m m-8 flex flex-col font-semibold text-primary-base">
      <p className="text-m text-center font-semibold text-black">
        {getTranslation('totalPrice').toUpperCase()}
        <span>:</span>
      </p>
      <strong className="pt-2 text-center text-xl">{formatMoney(amount)}</strong>
    </div>
  )
}

export default TotalToPay
